import React from "react"

const Features_Second = () => {
    return (
        <div id="features-second" className="features-wrapper section section--features section--center">
            <div className="wr">
                <div className="features features-trio features--left">
                    <div className="feature"><h3 className="feature__title color-1 weight-text-m">The commercial rights
                        belong to the
                        owner of the Vampires Of SOL.</h3>
                        <div className="feature__description" />
                    </div>
                    <div className="feature"><h3 className="feature__title color-1 weight-text-m">Community First.
                        Vampires Of SOL
                        owners will decide the future of the Vampire DAO.</h3>
                        <div className="feature__description" />
                    </div>
                    <div className="feature"><h3 className="feature__title color-1 weight-text-m">Handcrafted Art</h3>
                        <div className="feature__description" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Features_Second
