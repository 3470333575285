import "./Feature.scss"
import React from "react";

const Feature = () => {
    return (
        <div id="feature" className="section section--feature section--noPadding section--right">
            <div className="ft ft--right">
                <div className="ft__half pdx pdxParent--img-transparent">
                    <div className="pdxItem pdxItem--img-transparent">
                        <img src="/static/6jc6t4t0uv0pexlj.jpg?w=1200&amp;h=900&amp;fit=max"
                             srcSet="/static/6jc6t4t0uv0pexlj.jpg, /static/6jc6t4t0uv0pexlj.jpg?w=1200&amp;h=900&amp;fit=max&amp;dpr=2 2x" alt="" />
                    </div>
                </div>
                <div className="ft__half">
                    <div className="ft__wrapper">
                        <div className="ft__content"><h2
                            className="color-1 weight-title-h font-title section__title right noMargin">
                            Halloween Edition Drop Details</h2>
                            <p className="weight-text section__subtitle right noMargin" />
                            <div className="ft__description">
                                <div className="ftPoint">
                                    <div className="ftPoint__meta">
                                        <div className="ftPoint__description">
                                            <p>Mint price: 0.1 SOL</p>
                                            <p>Drop date: October 31</p>
                                            {/*<p>First 650 minters will get FREE MINT</p>*/}
                                        </div>
                                    </div>
                                </div>
                                {/*<div className="ftPoint">
                                    <div className="ftPoint__meta">
                                        <div className="ftPoint__description">
                                            <p>In order to participate you need a wallet score of minimum 500 points (wallet score is the sum of all the rarity scores of the 1st Generation Vampires of SOL NFTs in your participating wallet, based on the rarity score of each NFT on RarityMon, you can check your wallet score <a href="https://wallet-search.vampiresofsol.com/" rel="noopener noreferrer" target="_blank">here</a>).</p>
                                            <br />
                                            <p>Special 360 SOL reward for minters with wallet scores bigger than 2500 points.</p>
                                        </div>
                                    </div>
                                </div>*/}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Feature
