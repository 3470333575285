import React, { Fragment, useEffect, useState } from "react"

import "./Home.css"

import FeaturesSecond from "./Components/FeaturesSecond"
import FeaturesFirst from "./Components/FeaturesFirst"
import Roadmap from "./Components/Roadmap"
import Feature from "./Components/Feature"
import Header from "./Components/Header"
import Footer from "./Components/Footer"
import Rarity from "./Components/Rarity"
import Titles from "./Components/Titles"
import Media from "./Components/Media"
import Team from "./Components/Team"
import Hero from "./Components/Hero"
import Faq from "./Components/Faq"

import {AppProps} from "../utils/interface"
import {useAnchorWallet} from "@solana/wallet-adapter-react";
import {getCandyMachineState} from "../actions/candy-machine";
import * as anchor from "@project-serum/anchor";

const Home = (props: AppProps) => {

    const [remaining, setRemaining] = useState(0)

    const wallet = useAnchorWallet()

    const refreshCandyMachine = async () => {
        if(wallet) {
            const {
                itemsRemaining,
            } = await getCandyMachineState(
                wallet as anchor.Wallet,
                props.candyMachineId,
                props.connection
            )
            setRemaining(itemsRemaining)
        }
    }

    useEffect(() => {
        const interval = setInterval(refreshCandyMachine, 5000)
        return () => clearInterval(interval)
    })

    useEffect(() => {
        if(wallet) {
            refreshCandyMachine()
        }
    }, [wallet])

    return (
        <Fragment>
            <Header hideRemaining={true} remaining={remaining} {...props} />
            <Hero hideRemaining={false} remaining={remaining} {...props} />
            <Titles />
            <Media />
            <Feature />
            <Rarity />
            <FeaturesFirst />
            <FeaturesSecond />
            <Roadmap />
            <Faq />
            <Team />
            <Footer />
        </Fragment>
    );
};

export default Home;
