import "./Media.scss"

const Media = () => {
    return (
        <div id="media" className="section section--media section--left section--noPadding mediawrapper--cover">
            <img src="/static/pcnw171oux9vpkul.jpg" alt="" className="media media--image" />
        </div>
    )
}

export default Media
