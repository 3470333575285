import React from "react"

import "./Roadmap.scss"

const Roadmap = () => {
    return (
        <div id="roadmap" className="section section--zigzag section--center">
            <div className="wr"><h2
                className="color-1 weight-title-h font-title section__title center standalone">Roadmap</h2>
                <div className="zigzag showNumbers">
                    <div className="zigzagItem completed">
                        <div className="zigzagItem__graphic">
                            <div className="counter">1</div>
                        </div>
                        <div className="zigzagItem__content"><h3
                            className="zigzagItem__title color-1 weight-text-m">Release the
                            Vampires Of SOL</h3>
                            <div className="zigzagItem__text"><p>Successfully mint all 13,666 1st generation vampires
                                with a fair
                                minting process.</p></div>
                        </div>
                    </div>
                    <div className="zigzagItem completed">
                        <div className="zigzagItem__graphic">
                            <div className="counter">2</div>
                        </div>
                        <div className="zigzagItem__content"><h3
                            className="zigzagItem__title color-1 weight-text-m">Marketplace
                            Integration</h3>
                            <div className="zigzagItem__text"><p>Immediately after launch Vampires Of SOL will be
                                available to sell
                                and buy on our website and on top secondary marketplaces. </p></div>
                        </div>
                    </div>
                    <div className="zigzagItem completed">
                        <div className="zigzagItem__graphic">
                            <div className="counter">3</div>
                        </div>
                        <div className="zigzagItem__content"><h3
                            className="zigzagItem__title color-1 weight-text-m">Halloween Special:
                            Merchandise</h3>
                            <div className="zigzagItem__text"><p>Exclusive hoodies, shirts, and other thirsty merch.</p>
                            </div>
                        </div>
                    </div>
                    <div className="zigzagItem">
                        <div className="zigzagItem__graphic">
                            <div className="counter">4</div>
                        </div>
                        <div className="zigzagItem__content"><h3
                            className="zigzagItem__title color-1 weight-text-m">Special event for
                            Vampires Of SOL holders</h3>
                            <div className="zigzagItem__text"><p>Halloween Drop.</p></div>
                        </div>
                    </div>
                    <div className="zigzagItem">
                        <div className="zigzagItem__graphic">
                            <div className="counter">5</div>
                        </div>
                        <div className="zigzagItem__content"><h3
                            className="zigzagItem__title color-1 weight-text-m">Launching the
                            Vampire DAO vault</h3>
                            <div className="zigzagItem__text"><p>A % of all the royalty fees from the Vampires Of SOL
                                will be locked
                                in the Vampire DAO vault. </p></div>
                        </div>
                    </div>
                    <div className="zigzagItem">
                        <div className="zigzagItem__graphic">
                            <div className="counter">6</div>
                        </div>
                        <div className="zigzagItem__content"><h3
                            className="zigzagItem__title color-1 weight-text-m">Vampire DAO Token
                            Airdrop to Vampires Of SOL Holders</h3>
                            <div className="zigzagItem__text"><p>The genesis of Vampire DAO will be done by airdropping
                                to all
                                Vampires Of SOL the Vampire DAO token: community first no matter what! </p></div>
                        </div>
                    </div>
                    <div className="zigzagItem">
                        <div className="zigzagItem__graphic">
                            <div className="counter">7</div>
                        </div>
                        <div className="zigzagItem__content"><h3
                            className="zigzagItem__title color-1 weight-text-m">Vampire DAO
                            Launchpad</h3>
                            <div className="zigzagItem__text"><p>Our launchpad will support the community to help other
                                projects
                                with funding and a smooth launch. Allocations to private sales and pre-sales will be
                                reserved to
                                Vampires Of SOL holders. </p></div>
                        </div>
                    </div>
                    <div className="zigzagItem">
                        <div className="zigzagItem__graphic">
                            <div className="counter">8</div>
                        </div>
                        <div className="zigzagItem__content"><h3 className="zigzagItem__title color-1 weight-text-m">The
                            big reveal:
                            Dracula’s PvP Metaverse Game</h3>
                            <div className="zigzagItem__text"><p>Fight with other vampires in a dystopian universe and
                                gain SOL.
                                Every 1st generation vampire will be a playable character with special abilities and
                                in-game
                                attributes. </p></div>
                        </div>
                    </div>
                    <div className="zigzagItem">
                        <div className="zigzagItem__graphic">
                            <div className="counter">9</div>
                        </div>
                        <div className="zigzagItem__content"><h3
                            className="zigzagItem__title color-1 weight-text-m">Vampires live
                            forever….the Vampire DAO will decide the future...</h3>
                            <div className="zigzagItem__text" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Roadmap
