import React from "react"

import "./Rarity.scss"

const Rarity = () => {
    return (
        <div id="rarity" className="section section--feature section--noPadding section--left">
            <div className="ft ft--left">
                <div className="ft__half">
                    <div className="ft__wrapper">
                        <div className="ft__content"><h2
                            className="color-1 weight-title-h font-title section__title left noMargin">
                            Rarity</h2>
                            <p className="weight-text section__subtitle left noMargin" />
                            <div className="ft__description">
                                <div className="ftPoint">
                                    <div className="ftPoint__meta">
                                        <div className="ftPoint__description">
                                            <p>Each Vampire Of SOL is unique and comes in a
                                                variety of shapes, colors, but some Vampires Of SOL are more rare than
                                                the others.
                                                All vampires are members of Vampire DAO and share the benefits of this
                                                prestigious
                                                organisation: vampires hunt together!<br /><br /><br />Rarity chart is
                                                    available on
                                                    Discord!</p>
                                            <div className="ctas">
                                                <a href="https://discord.gg/GHGQeeXuzg" className="btn" target="_blank" rel="noreferrer">
                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                                        <path
                                                            d="M297.216 243.2c0 15.616-11.52 28.416-26.112 28.416-14.336 0-26.112-12.8-26.112-28.416s11.52-28.416 26.112-28.416c14.592 0 26.112 12.8 26.112 28.416zm-119.552-28.416c-14.592 0-26.112 12.8-26.112 28.416s11.776 28.416 26.112 28.416c14.592 0 26.112-12.8 26.112-28.416.256-15.616-11.52-28.416-26.112-28.416zM448 52.736V512c-64.494-56.994-43.868-38.128-118.784-107.776l13.568 47.36H52.48C23.552 451.584 0 428.032 0 398.848V52.736C0 23.552 23.552 0 52.48 0h343.04C424.448 0 448 23.552 448 52.736zm-72.96 242.688c0-82.432-36.864-149.248-36.864-149.248-36.864-27.648-71.936-26.88-71.936-26.88l-3.584 4.096c43.52 13.312 63.744 32.512 63.744 32.512-60.811-33.329-132.244-33.335-191.232-7.424-9.472 4.352-15.104 7.424-15.104 7.424s21.248-20.224 67.328-33.536l-2.56-3.072s-35.072-.768-71.936 26.88c0 0-36.864 66.816-36.864 149.248 0 0 21.504 37.12 78.08 38.912 0 0 9.472-11.52 17.152-21.248-32.512-9.728-44.8-30.208-44.8-30.208 3.766 2.636 9.976 6.053 10.496 6.4 43.21 24.198 104.588 32.126 159.744 8.96 8.96-3.328 18.944-8.192 29.44-15.104 0 0-12.8 20.992-46.336 30.464 7.68 9.728 16.896 20.736 16.896 20.736 56.576-1.792 78.336-38.912 78.336-38.912z"
                                                            fill="currentColor" />
                                                    </svg>
                                                    Discord</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="ft__half pdx pdxParent--img-transparent">
                    <div className="pdxItem pdxItem--img-transparent">
                        <img
                            src="/static/o6fihnwuapx9bfe4.jpg?w=1200&amp;h=900&amp;fit=max"
                            srcSet="/static/o6fihnwuapx9bfe4.jpg, /static/o6fihnwuapx9bfe4.jpg?w=1200&amp;h=900&amp;fit=max&amp;dpr=2 2x"
                            alt="" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Rarity
