import { useEffect, useState, Fragment } from "react";
import styled from "styled-components";
import Countdown from "react-countdown";
import { Button, CircularProgress, Snackbar } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";

import * as anchor from "@project-serum/anchor";

import { useAnchorWallet } from "@solana/wallet-adapter-react";
import { WalletDialogButton } from "@solana/wallet-adapter-material-ui";

import { WalletMintProps } from "../../utils/interface";

import {
    CandyMachine,
    awaitTransactionSignatureConfirmation,
    getCandyMachineState,
    mintOneToken
} from "../../actions/candy-machine";

import vampireScores from "../../config/score.json"
import {PublicKey} from "@solana/web3.js";

const ConnectButton = styled(WalletDialogButton)``;

const CounterText = styled.span``; // add your styles here

const MintContainer = styled.div``; // add your styles here

const MintButton = styled(Button)``; // add your styles here

const WalletMint = (props: WalletMintProps) => {

    const [vampireScore, setVampireScore] = useState(0);
    const [isActive, setIsActive] = useState(false); // true when countdown completes
    const [isSoldOut, setIsSoldOut] = useState(false); // true when items remaining is zero
    const [isMinting, setIsMinting] = useState(false); // true when user got to press MINT

    const [alertState, setAlertState] = useState<AlertState>({
        open: false,
        message: "",
        severity: undefined,
    });

    const [startDate, setStartDate] = useState(new Date(props.startDate));

    const wallet = useAnchorWallet();
    const [candyMachine, setCandyMachine] = useState<CandyMachine>();

    const onMint = async () => {
        try {
            setIsMinting(true);
            if (wallet && candyMachine?.program) {
                const mintTxId = await mintOneToken(
                    candyMachine,
                    props.config,
                    wallet.publicKey,
                    props.treasury
                );

                const status = await awaitTransactionSignatureConfirmation(
                    mintTxId,
                    props.txTimeout,
                    props.connection,
                    "singleGossip",
                    false
                );

                if (!status?.err) {
                    setAlertState({
                        open: true,
                        message: "Congratulations! Mint succeeded!",
                        severity: "success",
                    });
                } else {
                    setAlertState({
                        open: true,
                        message: "Mint failed! Please try again!",
                        severity: "error",
                    });
                }
            }
        } catch (error: any) {
            // TODO: blech:
            let message = error.msg || "Minting failed! Please try again!";
            if (!error.msg) {
                if (error.message.indexOf("0x138")) {
                } else if (error.message.indexOf("0x137")) {
                    message = `SOLD OUT!`;
                } else if (error.message.indexOf("0x135")) {
                    message = `Insufficient funds to mint. Please fund your wallet.`;
                }
            } else {
                if (error.code === 311) {
                    message = `SOLD OUT!`;
                    setIsSoldOut(true);
                } else if (error.code === 312) {
                    message = `Minting period hasn't started yet.`;
                }
            }

            setAlertState({
                open: true,
                message,
                severity: "error",
            });
        } finally {
            setIsMinting(false);
        }
    };

    useEffect(() => {
        (async () => {
            if (!wallet) return;

            const {
                candyMachine,
                goLiveDate,
                itemsRemaining,
            } = await getCandyMachineState(
                wallet as anchor.Wallet,
                props.candyMachineId,
                props.connection
            );

            setIsSoldOut(itemsRemaining === 0);
            setStartDate(goLiveDate);
            setCandyMachine(candyMachine);
        })();
    }, [wallet, props.candyMachineId, props.connection]);

    useEffect(() => {
        if(wallet) {

            const allowed : Array<string> = Object.keys(vampireScores)

            const makeReq = async () => {

                let score = 0

                const walletAddress : PublicKey = new anchor.web3.PublicKey(wallet.publicKey.toString())

                const tokens = await props.connection.getParsedTokenAccountsByOwner(walletAddress, {
                    // @ts-ignore
                    programId: new anchor.web3.PublicKey(process.env.REACT_APP_TOKEN_PROGRAM_ID)
                })

                for(let i = 0; i < tokens.value.length; i++) {
                    const token = tokens.value[i]
                    const nftAddress : string = token.account.data.parsed.info.mint
                    const tokenAmount : number = parseInt(token.account.data.parsed.info.tokenAmount.amount)
                    if(allowed.indexOf(nftAddress) === -1) continue
                    if(tokenAmount === 0) continue
                    // @ts-ignore
                    score += vampireScores[nftAddress]
                }

                setVampireScore(score)

            }
            makeReq()
        }
    }, [wallet])

    return (
        <div className="ctas-vertical">
            {!props.hideRemaining ?
                <Fragment>
                    <img src="/static/hero-vampire.jpg" alt="" />
                    {/*<div className="remaining">
                        {wallet && <p>Remaining: <strong>{props.remaining}</strong></p>}
                    </div>*/}
                </Fragment> :
            ''}
            <Fragment>

                <div className="spacer" style={{marginBottom: "20px"}} />

                <a
                    href="https://magiceden.io/marketplace?collection_symbol=vampires_of_sol"
                    className="MuiButtonBase-root MuiButton-root MuiButton-contained"
                    rel="noreferrer"
                    target="_blank"
                >BUY NOW ON MAGIC EDEN</a>

                <div className="spacer" />

                <a
                    href="https://digitaleyes.market/collections/Vampires%20Of%20SOL"
                    className="MuiButtonBase-root MuiButton-root MuiButton-contained"
                    rel="noreferrer"
                    target="_blank"
                >BUY NOW ON DigitalEyes</a>

                <div className="spacer" />

                <a
                    href="https://solsea.io/collection/615ee5176d3154c2fc6088dc"
                    className="MuiButtonBase-root MuiButton-root MuiButton-contained"
                    rel="noreferrer"
                    target="_blank"
                >BUY NOW ON SolSea</a>

            </Fragment>

            <Snackbar
                open={alertState.open}
                autoHideDuration={6000}
                onClose={() => setAlertState({ ...alertState, open: false })}
            >
                <Alert
                    onClose={() => setAlertState({ ...alertState, open: false })}
                    severity={alertState.severity}
                >
                    {alertState.message}
                </Alert>
            </Snackbar>
        </div>
    );
};

interface AlertState {
    open: boolean;
    message: string;
    severity: "success" | "info" | "warning" | "error" | undefined;
}

const renderCounter = ({ days, hours, minutes, seconds, completed }: any) => {
    return (
        <CounterText>
            {hours + (days || 0) * 24} hours, {minutes} minutes, {seconds} seconds
        </CounterText>
    );
};

export default WalletMint;
