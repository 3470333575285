import React from "react"

import "./Team.scss"

const Team = () => {
    return (
        <div id="team" className="section section--team section--center">
            <div className="wr titleAlign--top">
                <div className="titleWrapper">
                    <h2 className="color-1 weight-title-h font-title section__title center">Team</h2>
                    <p className="weight-text section__subtitle center" />
                </div>
                <div className="team team--center team--l3">
                    <div className="team__member">
                        <img src="/static/2lo43scxgb4cypwi.jpg?w=160&amp;h=160&amp;fit=crop&amp;crop=faces"
                             className="member__avatar"
                             srcSet="/static/2lo43scxgb4cypwi.jpg 2x"
                             alt="" />
                        <h3 className="member__name color-1 weight-text-m"><p>Mr. Vampire</p></h3>
                        <div className="member__description"><p>Just likes building stuff. </p></div>
                    </div>
                    <div className="team__member">
                        <img
                            src="/static/8dzls8c9rscez7k6.jpg?w=160&amp;h=160&amp;fit=crop&amp;crop=faces"
                            className="member__avatar"
                            srcSet="/static/8dzls8c9rscez7k6.jpg 2x"
                            alt="" />
                        <h3 className="member__name color-1 weight-text-m"><p>Vampire Bansky</p></h3>
                        <div className="member__description"><p>The mastermind behind the design.</p></div>
                    </div>
                    <div className="team__member">
                        <img src="static/4zms9apjo1m6c2ku.jpg?w=160&amp;h=160&amp;fit=crop&amp;crop=faces"
                            className="member__avatar"
                            srcSet="/static/4zms9apjo1m6c2ku.jpg 2x"
                            alt="" />
                        <h3 className="member__name color-1 weight-text-m"><p>&lt;/&gt; Vampire</p></h3>
                        <div className="member__description"><p>Lead developer... just boring.</p></div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Team
