import React, { useState, MouseEvent } from "react";

import { FaqItemProps } from "../../utils/interface"

const FaqItem = (props: FaqItemProps) => {

    const [closed, setClosed] = useState(true)

    const onClick = (event: MouseEvent) => {
        event.preventDefault()
        setClosed(!closed)
    }

    return (
        <div className={`faqItem ${closed ? 'closed': ''}`}>
            <h3 className="color weight-text-h faqItem__question link" onClick={onClick}>{props.question}</h3>
            <div className="faqItem__answer">
                {props.children}
            </div>
        </div>
    )

}

export default FaqItem
