import React from "react"

import "./Features.scss"

const Features_First = () => {
    return (
        <div id="features-first" className="features-wrapper section section--features section--center">
            <div className="wr">
                <div className="features features-trio features--left">
                    <div className="feature"><h3 className="feature__title color-1 weight-text-m">With over 100+ unique
                        attributes your
                        Vampire Of SOL will be truly one of a kind</h3>
                        <div className="feature__description" />
                    </div>
                    <div className="feature"><h3 className="feature__title color-1 weight-text-m">Mint an incredibly
                        rare Dracula
                        descendant Vampire Of SOL</h3>
                        <div className="feature__description" />
                    </div>
                    <div className="feature"><h3 className="feature__title color-1 weight-text-m">Exclusive benefits to
                        Vampires Of SOL
                        owners from the Vampire DAO membership</h3>
                        <div className="feature__description" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Features_First
