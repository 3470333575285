import React from "react"

import FaqItem from "./FaqItem"

import "./Faq.scss"

const Faq = () => {
    return (
        <div id="faq" className="section section--faq section--center">
            <div className="wr titleAlign--">
                <div className="titleWrapper">
                    <h2 className="color-1 weight-title-h font-title section__title center standalone">FAQ</h2>
                </div>
                <div className="faq expandable">
                    <FaqItem question="What are Vampires Of SOL NFT?">
                        <p>Vampires Of SOL are uniquely generated, not cute, evil, SOL thirsty vampires with proof of ownership ruling the Solana blockchain.</p>
                        <p>The 1st generation of Vampires are the founding fathers of Vampire DAO, earning SOL and many more!</p>
                        <p>Drop #1 5000 NFTs - Sold-out in 2 minutes</p>
                        <p>Drop #2 2500 NFTs Halloween Edition</p>
                    </FaqItem>
                    <FaqItem question="How do I purchase a Vampires Of SOL?">
                        <p>Purchasing a Vampires Of SOL is simple and only takes a few minutes:</p>
                        <ol>
                            <li>
                                <p>Download the Phantom or Sollet extension for your browser (chrome recommended)</p>
                            </li>
                            <li>
                                <p>Purchase SOL from any known exchanges (FTX, Binance, CoinBase, etc) and send it
                                    to your wallets Public Address.
                                </p>
                            </li>
                            <li>
                                <p>Connect your Wallet to our website by clicking on the Connect button.</p>
                            </li>
                        </ol>
                        <p>Click the “Summon” button on <a href="https://vampiresofsol.com">https://vampiresofsol.com</a>
                            and approve the transaction in your Wallet.
                        </p>
                    </FaqItem>
                    <FaqItem question="Where can I find my Vampires Of SOL after purchase?">
                        <p>After purchase you will be able to view your Vampires Of SOL in the NFT tab of your wallet.</p>
                    </FaqItem>
                    <FaqItem question="When is the drop?">
                        <p>The Halloween drop date is Saturday, October 31 @ 3PM UTC</p>
                    </FaqItem>
                    <FaqItem question="What is the minting price?">
                        <p>Minting price is 0.1 SOL</p>
                        {/*<p>First 650 minters will get FREE MINT</p>*/}
                        {/*<p>In order to participate you need a wallet score of minimum 500 points (wallet score is the sum of all the rarity scores of the 1st Generation Vampires of SOL NFTs in your participating wallet, based on the rarity score of each NFT on RarityMon, you can check your wallet score <a href="https://wallet-search.vampiresofsol.com/" rel="noopener noreferrer" target="_blank">here</a>)</p>*/}
                        {/*<p>Special 360 SOL reward for minters with wallet scores bigger than 2500 points</p>*/}
                    </FaqItem>
                    <FaqItem question="What wallets are compatible?">
                        <p>Vampires Of SOL are compatible with Solana’s main wallets: Phantom, Sollet and Solflare</p>
                    </FaqItem>
                    <FaqItem question="Will Vampires Of SOL: Halloween Edition be available on a secondary market?">
                        <p>Yes, same as 1st generation Vampires of SOL</p>
                    </FaqItem>
                    <FaqItem question="How can I check my Wallet Score?">
                        <p>You can do that <a href="https://wallet-search.vampiresofsol.com/" rel="noopener noreferrer" target="_blank">here</a>.</p>
                    </FaqItem>
                    <FaqItem question="If I have multiple wallets with wallet score bigger than >2500 points can I participate with all of them to share the prize pool?">
                        <p>Yes!</p>
                    </FaqItem>
                    <FaqItem question="How can I increase my wallet score?">
                        <p>You need to hold 1st Generation Vampires in your wallet, if you don’t have any you can purchase from the marketplaces:</p>
                        <ul>
                            <li><a href="https://magiceden.io/marketplace?collection_symbol=vampires_of_sol" rel="noopener noreferrer" target="_blank">Magic Eden</a></li>
                            <li><a href="https://digitaleyes.market/collections/Vampires%20Of%20SOL" rel="noopener noreferrer" target="_blank">DigitalEyes</a></li>
                            <li><a href="https://solsea.io/collection/615ee5176d3154c2fc6088dc" rel="noopener noreferrer" target="_blank">SolSea</a></li>
                        </ul>
                    </FaqItem>
                    <FaqItem question="Will the Vampires of SOL Halloween Edition have special powers in the upcoming game?">
                        <p>Yes!</p>
                    </FaqItem>
                    <FaqItem question="Will holding 1st Generation Vampires of SOL have benefits in the future?">
                        <p>Everything is related to the 1st Generation Vampires — future drops will require higher wallet scores and many more! so YES!</p>
                    </FaqItem>
                </div>
            </div>
        </div>
    )
}

export default Faq
