import "./Titles.scss"

const Titles = () => {
    return (
        <div id="titles" className="section section--titles section--center">
            <div className="wr"><h2 className="color-1 weight-title-h font-title section__title center">After selling out in 2 minutes the 1st generation of Vampires of SOL, the SOL hungry beast are back with a second drop of 2,500 limited edition vampires for Halloween 🎃</h2>
                {/*<p className="weight-text section__subtitle center">The 1st generation of Vampires are the founding
                    fathers of
                    Vampire DAO, earning SOL and many more!</p>*/}</div>
        </div>
    )
}

export default Titles;
