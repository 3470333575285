import { WalletMintProps } from "../../utils/interface"
import WalletMint from "./WalletMint"

import "./Hero.scss"

const Hero = (props: WalletMintProps) => {
    return (
        <div id="hero" className="section section--hero section--noPadding section--center">
            <div id="headerSpacer" />
            <div className="ft ft--center">
                <div className="ft__half">
                    <div className="ft__wrapper">
                        <div className="ft__content"><h1
                            className="hero__title color-1 weight-title-h font-title">Vampires of SOL 2nd Drop: Halloween Edition</h1>
                            <p className="hero__subtitle weight-text">Because SOL lovin’ vampires go wild on Halloween.</p>
                            <div className="ctas">
                                <WalletMint {...props} />
                                {/*<div className="ctas-vertical">
                                    <img src="/static/hero-vampire.jpg" alt="" />
                                    <a
                                        href="https://magiceden.io/marketplace?collection_symbol=vampires_of_sol"
                                        className="MuiButtonBase-root MuiButton-root MuiButton-contained"
                                        rel="noreferrer"
                                        target="_blank"
                                    >BUY NOW ON MAGIC EDEN</a>
                                    <a
                                        href="https://digitaleyes.market/collections/Vampires%20Of%20SOL"
                                        className="MuiButtonBase-root MuiButton-root MuiButton-contained"
                                        rel="noreferrer"
                                        target="_blank"
                                    >BUY NOW ON DigitalEyes</a>
                                </div>*/}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="ft__half pdx pdxParent--img-transparent">
                    <div className="pdxItem pdxItem--img-transparent" />
                </div>
            </div>
        </div>
    );
};

export default Hero;
